<template>
  <div class="bg-fff" style="height: 100%;">
    <nav-header title="转移负责人"/>
    <div class="nei-rong">
      <div class="ti-shi">
        此操作请谨慎操作<br/>
        如果您是服务商，或者是客户，将会全部转移出去
      </div>
      <van-form @submit="onSubmit" style="margin-top: 10px">
        <div class="padding-10">
          <van-cell-group inset>
            <van-field
                v-model="form.mobile"
                label="当前手机"
                disabled
            />
            <van-field
                required
                v-model="form.code"
                label="验证码"
                placeholder="请输入验证码"
            >
              <template #button>
                <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
              </template>
            </van-field>
            <van-field
                v-model="form.lxr_name"
                label="选择员工"
                required
                is-link
                readonly
                @click="$refs.selectYuanGong.show()"
            >

            </van-field>

          </van-cell-group>
        </div>
        <div class="form-btn">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <select-yuan-gong
        v-model:id="form.lxr_id"
        v-model:name="form.lxr_name"
        ref="selectYuanGong"
    />
  </div>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import yanZhengMa from "../open/yanZhengMa";
import http from "../../api/http";
import SelectYuanGong from "../../components/select/selectYuanGong";
import din_shi from "../../api/din_shi";

export default {
  name: 'ZhuanYiFuZeRen',
  components: {SelectYuanGong, NavHeader},
  mixins: [yanZhengMa],
  data() {
    return {
      selectPage: true,
      form: {}
    }
  },
  mounted() {
    this.getMobiel().then(mobile => {
      this.form.mobile = mobile
    })
  },
  methods: {
    onSubmit() {
      http.post('/gzhphp/user/ZhuanYiFuZeRen', this.form, {
        load_success: true
      }).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(() => {
            this.$router.push({name: 'UserHome'})
          })
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.ti-shi {
  color: @pei-se;
  padding: 10px;
}

.nei-rong {

  //padding: 10px;
}
</style>
