<template>
  <div>
    <van-popup
        v-model:show="pageStatus"
        round
        position="bottom"
        :style="{ height: '300px' }"
    >
      <div class="hua-dong">
        <div class="user-list" v-for="vo in list" :key="vo.id" @click="onClick(vo)">
          <div class="user-image">
            <van-image round width="20" height="20" :src="vo.avatar"/>
          </div>
          <div class="user-name van-ellipsis">
            {{ vo.user_nickname }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import http from "../../api/http";

export default {
  name: 'selectYuanGong',
  mixins: [],

  data() {
    return {
      pageStatus: false,
      list: []
    }
  },
  mounted() {
    this.setYuanGong()
  },
  methods: {
    show() {
      this.pageStatus = true
    },
    setYuanGong() {
      http.get('/gzhphp/user/getYuanGong').then(da => {
        if (da.code === 1) this.list = da.data
      })
    },
    onClick(item) {
      this.pageStatus = false
      this.$emit('update:id', item.id)
      this.$emit('update:name', item.user_nickname)
    }
  },

}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.user-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  margin-top: 10px;

  .user-image {
    padding-right: 10px;
  }

  .user-name {
    color: @blue;
  }
}

.hua-dong {
  height: 270px;
  overflow-y: auto;
}
</style>
